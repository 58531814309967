import React, {useEffect, useState} from "react";
import "../styles/image-gallery.css";
import ImageGallery from "react-image-gallery";
import banner from "../asset/banner.webp"
import {Badge, Card} from "react-bootstrap";
import {Avatar, Stack} from "@mui/material";
import imgOriginal from "../asset/original.webp";
import {FaPinterestP} from "react-icons/fa";
import ModelPartReport from "../BookingDetails/component/ModelPartReport";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon,
    LinkedinShareButton, TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {cropText} from "../../hooks/useCustomHelpingMethod";
import {appName} from "../../api/constant";


export default function ProductGallery({img, discount, data}) {
    const [image, setImage] = useState([]);


    useEffect(() => {
        console.log(img);
        console.log(data);
    }, []);

    const getData = () => {
        if (img && Array.isArray(img) && img.length > 0) {
            let imageTemp = []
            img?.map((element, idx) => {
                imageTemp = [...imageTemp, {
                    original: element,
                    thumbnail: element,
                    // thumbnailHeight:"20px",
                    // thumbnailWidth:"auto",
                }];
            })
            setImage(imageTemp)
        } else {
            setImage([{
                original: banner,
                thumbnail: banner
            }])
        }

        // img?.map((element, idx) => {
        //     images[idx] = {
        //         original: element,
        //         thumbnail: element,
        //         // thumbnailHeight:"20px",
        //         // thumbnailWidth:"auto",
        //     };
        //     setImage(images)
        // })
    };

    // function share_on_whatsapp() {
    //     const u = window.location.href;
    //     // const u = "https://iserviceindia.in/product/huawei-gr5-2017-display-with-touch-screen-replacement-combo";
    //     const t = "Hey, I found an awesome product in very reasonable price. Please checkout on " + u;
    //     window.open('https://api.whatsapp.com/send?phone=&text=' + encodeURIComponent(t), 'sharer', 'toolbar=0,status=0,width=626,height=436');
    //     return false;
    // }

    // function share_on_facebook() {
    //     // const u = window.location.href;
    //     const u = "https://testing.iserviceindia.in/product/asus-rog-phone-5-zs673ks-lcd-display-combo-folder-wf";
    //     const t = document.title;
    //     window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(u) + '&t=' + encodeURIComponent(t), 'sharer', 'toolbar=0,status=0,width=626,height=436');
    //
    //     // window.FB.ui(
    //     //     {
    //     //         display: 'popup',
    //     //         method: 'share',
    //     //         href: u
    //     //     },
    //     //     function (response) {}
    //     // );
    //     //
    //     // return false;
    // }

    // function share_on_twitter() {
    //     const u = window.location.href;
    //     // const u = "https://iserviceindia.in/product/huawei-gr5-2017-display-with-touch-screen-replacement-combo";
    //     // const t = document.title;
    //     const t = "Hey, I found an awesome product in very reasonable price. Please checkout on " + u;
    //     // window.open('http://www.twitter.com/intent/tweet?url=' + encodeURIComponent(u) + '&text=' + encodeURIComponent(t), 'sharer', 'toolbar=0,status=0,width=626,height=436');
    //     window.open('http://www.twitter.com/intent/tweet?text=' + encodeURIComponent(t), 'sharer', 'toolbar=0,status=0,width=626,height=436');
    //     return false;
    // }

    // function share_on_linkedin() {
    //     const u = window.location.href;
    //     // const u = "https://iserviceindia.in/product/huawei-gr5-2017-display-with-touch-screen-replacement-combo";
    //     const t = document.title;
    //     window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(u), 'sharer', 'toolbar=0,status=0,width=626,height=436');
    //     return false;
    // }

    function share_on_pinterest() {
        const u = window.location.href;
        // const u = "https://iserviceindia.in/product/huawei-gr5-2017-display-with-touch-screen-replacement-combo";
        // const t = document.title;
        var media = data?.url_icon || data?.url_image || "";
        var desc = document.title || "Hey, I found an awesome product in very reasonable price. Please checkout on " + u;
        window.open('http://pinterest.com/pin/create/link/?url=' + encodeURIComponent(u) + "&media=" + media + "&description=" + desc, 'sharer', 'toolbar=0,status=0,width=626,height=436');
        return false;
    }

    
 

    useEffect(() => {
        // console.log(1);
        setImage([]);
        getData()
    }, [img]);
    // const imag="https://s3.ap-south-1.amazonaws.com/aim-app-iservice-data-bucket-4/iservice/image/product/part/21829/icon-1038648043-1708329513.webp";
    //  const url = "https://testing.iserviceindia.in/product/asus-rog-phone-5-zs673ks-lcd-display-combo-folder-wf";
    const url = window.location.href;


const [zoomStyle, setZoomStyle]=useState({});
const [showZoomWindow, setShowZoomWindow] = useState(false); // To control visibility of the zoom window

const handleMouseMove = (e, item) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { offsetWidth, offsetHeight } = target;

    const xPercent = (offsetX / offsetWidth) * 100;
    const yPercent = (offsetY / offsetHeight) * 100;
    
    setZoomStyle({
        backgroundImage: `url(${item.original})`,
        backgroundPosition: `${xPercent}% ${yPercent}%`,
        backgroundSize: '200%', 
        transition: 'background-position 0.1s ease-out', 
    });
};

const handleMouseEnter = () => {
    setShowZoomWindow(true); 
};

const handleMouseLeave = () => {
    setShowZoomWindow(false); 
};

const renderZoomItem = (item) => (
    <div
        className="zoom-container"
        onMouseMove={(e) => handleMouseMove(e, item)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        style={{
            backgroundImage: `url(${item.original})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            
        }}
    >
        <img
            src={item.original}
            alt="Zoomed Image"
            className="gallery-image"
            style={{
                visibility: 'hidden', 
                width: '100%',
                height: '100%',
                
            }}
        />
    </div>
);

    return (
        // <div className="wrapper gg">
        //     <Card className={'card-custom'}>
        //         <Card.Body className={'p-2'}>
                
        //             <ImageGallery
        //                 items={image}
        //                 showPlayButton={false}
        //                 useBrowserFullscreen={false}
        //                 renderItem={renderZoomItem}
        //                 showNav={true}
        //                 onErrorImageURL={banner}
        //                 thumbnailPosition={"bottom"}
        //                 lazyLoad={true}
        //                 infinite={true}
        //                 showThumbnails={true}
                
        //             />
        //             {data?.setting?.is_original && data?.setting?.is_original === 'yes' &&
        //             <img src={imgOriginal} alt={data?.title || "part"} className={'badge-top-left original-img'} />
                   
        //               }
                    
        //             {showZoomWindow && (
        //                 <div
        //                     className="zoom-window"
        //                     style={{
        //                         backgroundImage: zoomStyle.backgroundImage,
        //                         backgroundPosition: zoomStyle.backgroundPosition,
        //                         backgroundSize: zoomStyle.backgroundSize,
        //                     }}
        //                 />
        //             )}

        //             {discount && discount > 0 ?
        //                 <Badge pill bg="danger" className={'badge-top-right'}>-{discount}%</Badge> : ""}
        //         </Card.Body>
        //         {/* <Card.Footer>
        //             <Alert variant="outlined" severity="info" className={'align-items-center'}>
        //                 Image for presentation only. Actual product can be different from the product shown.
        //             </Alert>
        //             <p className={'text-center'}><small >Image for presentation only. Actual product can be different from the product shown</small></p>
        //         </Card.Footer>*/}
        //     </Card>

        <div className="wrapper gg">
    <Card className={'card-custom'}>
        <Card.Body className={'p-2'}>
        
            <ImageGallery
                items={image}
                showPlayButton={false}
                useBrowserFullscreen={false}
                renderItem={renderZoomItem}
                showNav={true}  
                onErrorImageURL={banner}
                thumbnailPosition={"bottom"}
                lazyLoad={true}
                infinite={true}
                showThumbnails={true}
            />
            {data?.setting?.is_original && data?.setting?.is_original === 'yes' &&
            <img src={imgOriginal} alt={data?.title || "part"} className={'badge-top-left original-img'} />
            }
            
            {showZoomWindow && (
                <div
                    className="zoom-window"
                    style={{
                        backgroundImage: zoomStyle.backgroundImage,
                        backgroundPosition: zoomStyle.backgroundPosition,
                        backgroundSize: zoomStyle.backgroundSize,
                    }}
                />
            )}

            {discount && discount > 0 ? 
                <Badge pill bg="danger" className={'badge-top-right'}>-{discount}%</Badge> : ""}
        </Card.Body>
    </Card>


            <div className={"mt-3"}>
                <ModelPartReport part={data}/>
            </div>

            <div className={"mt-3"}>
                <div className={'d-block text-center align-items-center d-flex '}>
                    <b className={'mx-2'}>Share: </b>
                    <Stack direction="row" spacing={1} className={'mx-auto mx-md-0'}>
                        <FacebookShareButton
                            url={url || ""}
                            title={data?.title | ""}
                            quote={data?.title | ""}
                            // imageURL={data?.url_icon || ""}
                            media={data?.url_icon || ""}
                            image={data?.url_icon || ""}
                            description={cropText(data?.page_description || data?.description || "", 100)}
                        >
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>
                        <WhatsappShareButton
                            url={url || ""}
                            title={data?.title | ""}
                            image={img[0]?.url_icon || ""}
                            
                            className="m-1">
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <LinkedinShareButton
                            url={url || ""}
                            source={appName}
                            title={data?.title | ""}
                            summary={cropText(data?.page_description || data?.description || "", 100)}
                            media={data?.url_icon || ""}
                            className="m-1">
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>
                        <TwitterShareButton
                            url={url || ""}
                            hashtags={appName}
                            title={data?.title | ""}
                            className="m-1">
                            <TwitterIcon size={30} round={true}/>
                        </TwitterShareButton>
                        {/*<FacebookShareButton
                            url={"https://www.pakkamarwadi.tk/"}
                            quote={title}
                            hashtag={'#portfolio...'}
                        >
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>*/}

                        {/*<Avatar onClick={share_on_whatsapp} className={'social-icon'}
                                sx={{bgcolor: "#00b489", width: 30, height: 30}} alt="wa"><FaWhatsapp
                            size={15}/></Avatar>*/}

                        {/* <Avatar onClick={share_on_facebook} className={'social-icon'}
                                sx={{bgcolor: "#3B5998", width: 30, height: 30}} alt="fb"><FaFacebookF
                            size={15}/></Avatar>*/}

                        {/*<Avatar onClick={share_on_linkedin} className={'social-icon'}
                                sx={{bgcolor: "#007bb5", width: 30, height: 30}} alt="in"><FaLinkedinIn
                            size={15}/></Avatar>*/}

                        {/*<Avatar onClick={share_on_twitter} className={'social-icon'}
                                sx={{bgcolor: "#55acee", width: 30, height: 30}} alt="tw"><FaTwitter
                            size={15}/></Avatar>*/}

                        <Avatar onClick={share_on_pinterest} className={'social-icon m-1'}
                                sx={{bgcolor: "#cb2027", width: 30, height: 30}} alt="pi"><FaPinterestP
                            size={15}/></Avatar>

                        {/*<Avatar onClick={() => window.open("https://www.instagram.com/?url=" + (window.location.href))} className={'social-icon'} sx={{bgcolor: "#125688", width: 30, height: 30}} alt="In"><FaInstagram size={15}/></Avatar>*/}

                    </Stack>
                </div>

            </div>
        </div>

    )
}



// metaUtils.js - Utility function to update meta tags
export const updateMetaTags = (metaData) => {
    const { title, description, keywords, url, image } = metaData;
  
    // Update the document title
    document.title = title || 'Default Title';
  
    // Helper function to update or create meta tags dynamically
    const updateMetaTag = (name, content) => {
      let metaTag = document.querySelector(`meta[property="${name}"], meta[name="${name}"]`);
      
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name); // Or 'property' for og:title, etc.
        document.head.appendChild(metaTag);
      }
  
      metaTag.setAttribute('content', content); // Set the content of the meta tag
    };
  
    // Update the basic meta tags
    if (title) updateMetaTag('title',title);
    if (description) updateMetaTag('description', description);
    if (keywords) updateMetaTag('keywords', keywords);

    if (url) updateMetaTag('url', url);
    if (image) updateMetaTag('image',image)
        console.log(image)
    // Update Open Graph meta tags (og:title, og:description)
    if (title) updateMetaTag('og:title', title);
    if (description) updateMetaTag('og:description', description);
    if (keywords) updateMetaTag('og:keywords',keywords);
    if (image) updateMetaTag('og:image',image);

    // Twitter
      // Update Twitter Card meta tags
  if (title) updateMetaTag('twitter:title', title);
  if (description) updateMetaTag('twitter:description', description);
  if (image) updateMetaTag('twitter:image', image);
  if (url) updateMetaTag('twitter:url', url);
  if (image) updateMetaTag('twitter:image:src', image); // For image source in Twitter cards
  updateMetaTag('twitter:card', 'summary_large_image');
  };
  
  
import React, {useEffect, useState} from "react";
import ContactForm from "./ContactForm";
import {BsTelephone, BsWhatsapp} from "react-icons/bs";
import {appAddress, appName, appPhoneNumber} from "../../api/constant";
// import {HelmetCustomInclude} from "../HelmetCustomInclude";
import MetaTagsUpdater from "../../Metautils/meta";

export default function ContactUsMain() {
    const [pageTitle, setPageTitle] = useState("Contact Us | We are here to assist you and provide the best customer service");
    const styleIcon = {color: "#ee610f", fontSize: "1.2em"}
    const metaData = {
        title:pageTitle,
        url:window.location.href
    }
    return (
        <div>
            {/* <HelmetCustomInclude url={"contact-us"} title={pageTitle} image={null}/> */}
        <MetaTagsUpdater metaData={metaData}/>
            <section className="">
                <div className="ourservices">
                    <h3 className=" mx-5 servicesHeading">Contact Us</h3>
                    <p className="mx-5 subHeading">Need more information? Feel free to talk to our representative. We’re
                        here to help!</p>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 ">
                            <ContactForm/>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="card shadow my-sm-5 my-2">
                                <div className="card-body">
                                    <div className="">
                                        <h4 className={'py-2'}>iService <span className={'color-primary'}>Support</span>
                                        </h4>
                                        <p>If you are facing any issues with our products or services, Please feel free
                                            to contact us on given channels for resolution</p>
                                        <p><b>(Available from Monday to saturday 11 am to 6 pm)</b></p>
                                        <p>
                                            <strong>+91 {appPhoneNumber}</strong>
                                            {/* <strong>+91 9713300060</strong> */}
                                            <a href={"tel:91" + appPhoneNumber} className={'float-end me-4'}>
                                                <BsTelephone style={styleIcon}/>
                                            </a>

                                            <a href={"https://api.whatsapp.com/send?phone=91" + appPhoneNumber}
                                               className={'float-end me-4'}>
                                                <BsWhatsapp style={styleIcon}/>
                                            </a>
                                        </p>
                                        {/* <strong>+91 9713300066</strong> */}
                                        {/* <p>l̥
                                            <strong>+91 9329520545</strong>
                                           
                                            <a href={"tel:9329520545"} className={'float-end me-4'}>
                                                <BsTelephone style={styleIcon} />
                                            </a>
                                        </p> */}
                                        {/*<p><b>Bhopal office</b></p>
                                        <p>
                                            <strong>+91 9329520545</strong>
                                            <a href={"tel:9329520545"} className={'float-end me-4'}>
                                                <BsTelephone style={styleIcon}/>
                                            </a>

                                            <a href={"https://api.whatsapp.com/send?phone=9329520545"}
                                               className={'float-end me-4'}>
                                                <BsWhatsapp style={styleIcon}/>
                                            </a>
                                        </p>*/}
                                        <hr/>
                                        <h4 className={'pb-2'}>Postal <span className={'color-primary'}>Contact</span>
                                        </h4>
                                        <h6>{appName}</h6>
                                        <p>{appAddress}</p>
                                        {/*<div className="d-flex  justify-content-center my-4" >*/}

                                        {/*<iframe src="https://maps.google.com/maps?q=iService: Mobile, Laptop, TV, Camera, Drone, Printer, Gaming Consoles Repair, 1 st A block Floor Jyoti Cineplex Jyoti Talkies, Madhya Pradesh 462011&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="700" height="520"  allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

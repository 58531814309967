import React, { useEffect, useState } from "react";
import {
    category_list,
    model,
    part_list,
    product_listing_by_id,
    product_model,
} from "../../api/general";
import MoreButton from "./MoreButton";
import { useNavigate } from "react-router-dom";
import { useAuth, useNotification } from "../../hooks";
import Select from 'react-select';
export default function TabsForm1() {
    const [product_details, setProduct_details] = useState([]);
    const [brand_details, setBrand_details] = useState([]);
    const [part_details, setPart_details] = useState([]);
    const [brandFetch, setbrandFetch] = useState();
    const [brand_id, setbrand_id] = useState();
    const [model_slug, setModel_slug] = useState();
    const [brand_slug, setBrand_slug] = useState();
    const [category_slug, setCategory_slug] = useState();
    const { setShowLoginModel } = useNotification();
    const { authInfo } = useAuth();
    const { setOpenBackDrop } = useNotification();

    const navigate = useNavigate();

    useEffect(() => {
        setOpenBackDrop(true);
        const getUsers = async () => {
            const { data } = await category_list({ is_part: "yes" });
            setProduct_details(data.data);
        };
        setOpenBackDrop(false);


        getUsers();
    }, []);

    useEffect(() => {
        const getUsers = async () => {
            setBrand_details([])
            setPart_details([])
            setOpenBackDrop(true);
            const { data } = await model({ category_id: brandFetch });
            setBrand_details(data.data);
            setOpenBackDrop(false);

        };
        getUsers();
    }, [brandFetch]);

    const onChange = async (event) => {
        // setBrand_slug(event.target.selectedOptions[0].getAttribute('slug'))
        setBrand_slug(event.slug)
        const value = event.value;
        setbrand_id(value);
        setOpenBackDrop(true);
        const { data } = await product_model({ brand_id: value });
        setPart_details(data.data);
        setOpenBackDrop(false);

    };
    const onClick = async () => {
        // console.log(brand_slug)
        if (model_slug) {
            // navigate("/shop?brand-slug=" + brand_slug +"&category-slug=" +category_slug + "&model-slug=" + model_slug);
            navigate("/product?brand-slug=" + brand_slug + "&category-slug=" + category_slug + "&model-slug=" + model_slug);
        } else if (brand_slug) {
            // navigate("/shop?brand-slug=" + brand_slug +"&category-slug=" +category_slug );
            navigate("/product?brand-slug=" + brand_slug + "&category-slug=" + category_slug);
        } else if (category_slug) {
            // navigate("/shop?category-slug=" +category_slug);
            navigate("/product?category-slug=" + category_slug);
        } else {
            // navigate("/shop");
            navigate("/product");
        }
    };
    const onChangeModel = (event) => {
        const value = event.value;
        console.log(value)
        setModel_slug(value);
    };
    return (
        <>
            <form className="">
                {/* <select
                    onChange={(e) => {
                        setbrandFetch(e.target.value);
                        setCategory_slug(e.target.selectedOptions[0].getAttribute('slug'))
                    }}
                    className="form-select my-5"
                    aria-label="Default select example"
                >
                    <option className="tabs-dropdown-placeholder">
                        Select Category
                    </option>
                    {product_details.map((element) => {
                        return <option key={element?.token} value={element.id}
                            slug={element.slug}>{element.title}</option>;
                    })}
                </select> */}
                <div className="mt-5 important">
                 <Select
                    options={product_details.map((element) => ({
                        value: element.id,
                        label: element.title,
                        slug: element.slug,
                    }))}
                    onChange={(selectedOption) => {
                        setbrandFetch(selectedOption.value);
                        setCategory_slug(selectedOption.slug);
                    }}
                    placeholder="Select Category"
                    isSearchable
                    isClearable
                />
                </div>
                {/* <select
                    onChange={onChange}
                    className="form-select my-5"
                    aria-label="Default select example"
                >
                    <option>Select Brand</option>
                    {brand_details === null || !brand_details.length ? (
                        <option value="1">Please Select Category</option>
                    ) : (
                        brand_details.map((element) => {
                            return <option key={element?.token} value={element.id}
                                slug={element.slug}>{element.title}</option>;
                        })
                    )}
                </select> */}
                
                <div className="mt-5 important">
                <Select
    options={brand_details.map((element) => ({
        value: element.id,   // Use brand id as the value
        label: element.title,  // Display the title as the label
        slug: element.slug,  // Optionally add slug if needed
    }))}
    onChange={onChange} // Pass the selected option
    placeholder="Select Brand"
    isSearchable
    isClearable
    isDisabled={!product_details.length} // Disable until category is selected
/>

              </div>
                {/* <select
                    onChange={onChangeModel}
                    className="form-select my-4"
                    aria-label="Default select example"
                >
                    <option>Select Model</option>
                    {part_details === null || !part_details.length ? (
                        <option value="1">Please Select Model</option>
                    ) : (
                        part_details.map((element) => {
                            return <option key={element?.token} value={element.slug}>{element.title}</option>;
                        })
                    )}
                </select> */}
                
                <div className="mt-5 important">
                  <Select
                    options={part_details.map((element) => ({
                        value: element.slug, // Use slug as the value
                        label: element.title, // Display title as the label
                    }))}
                    onChange={onChangeModel}
                    placeholder="Select Model"
                    isSearchable
                    isClearable
                    isDisabled={!brand_details.length} // Disable until brand is selected
                />
                </div>
                
                <div className="mt-4">
                <div className="d-flex justify-content-center align-item-center">
                 <MoreButton onClickChange={onClick}>View Parts</MoreButton>  

                </div>
                </div>
            </form>
        </>


    );
}

import React, {useState, useEffect} from "react";
import {
    Col,
    Container,
    Row, Button,
} from "react-bootstrap";
import {
    Box,
    CircularProgress,
    IconButton,
    InputBase,
    Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useAuth} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import {BlogItemCard} from "./BlogItemCard";
import MetaTagsUpdater from "../../Metautils/meta";
// import {HelmetCustomInclude} from "../HelmetCustomInclude";


const BlogList = () => {
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [offset, setOffset] = useState(0);
    const [isMoreData, setIsMoreData] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [data, setData] = useState([])

    const {authInfo} = useAuth();

    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "limit": 10,
        "offset": 0
    })

    const getData = async () => {
        setLoading(true)
        setOffset(0)
        const inputData = {
            offset: 0
        }
        if (searchInput) {
            inputData.search_text = searchInput;
        }
        const response = await ApiCall("dealer/blog", {...bodyData, ...inputData})


        if (response?.error === false) {
            setData(response?.data)
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
        } else {
            setData([])
        }
        setLoading(false)
    }


    const nextData = async () => {
        setBtnLoading(true);
        setIsLoadingMore(true);

        const inputData = {
            offset: offset || 0
        }
        if (searchInput) {
            inputData.search_text = searchInput;
        }
        const response = await ApiCall("dealer/blog", {...bodyData, ...inputData})

        if (response?.error === false) {
            // setData(response?.data)
            if (response.data.length < 6) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
            if (response.data.length) {
                setData((prev) => [...new Set([...prev, ...response.data])]);
            }
        } else {
            setData([])
        }
        setIsLoadingMore(false);
        setBtnLoading(false);
    }

    useEffect(() => {
        // console.log(product_details);
        if (offset !== 0) {
            nextData();
        }
    }, [offset]);
    useEffect(() => {
        // console.log(product_details);
        getData();
    }, [searchInput]);

    const cardStyles = {
        marginBottom: "20px",
    };

    const styleFontText = {
        fontSize: "22px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontFamily: "'Nunito Sans', sans-serif",
    };

    const handelChangeSearch = async (e) => {
        setSearchInput(e.target.value);
    };
    const handelInfiniteScroll = async () => {
        setOffset(offset + 1);
    };
    const styleCard = {
        // border: "1px solid var(--primary)"
    };
    const metaData={
        title:"Our Blogs",
        url:window.location.href 
        
    }
    return (
        <>
            {/* <HelmetCustomInclude url={"blog"} title={"Our Blogs"} image={null}/> */}
            <MetaTagsUpdater metaData={metaData}/>

            <div className="ourservices ">
                <h3 className=" mx-lg-5 servicesHeading">Our Blogs</h3>
                <p className="mx-lg-5 subHeading">
                    {/*Get Spare Parts at low prices on iService. Wide range of Spare Parts. Get Free Shipping & COD options across India*/}
                    {/*iService offers low-cost spare parts. A large selection of spare parts is available. Free shipping*/}
                    {/*and cash-on-delivery (COD) are available in many Indian cities.*/}

                </p>
            </div>
            <Container className=" mt-4 pt-3 mb-5">
                {/* {loading && <Row> <SkeletonFileGeneral height={40} col={12} total={1}/> </Row>} */}
                <Row>
                    <Col>
                        <Box role="presentation">
                            <Paper
                                sx={{p: '3px 3px', display: 'flex', alignItems: 'center', width: "100%"}}>
                                <InputBase sx={{ml: 1, flex: 1}}
                                    // onChange={handleChange}
                                           name="search_text"
                                           onChange={handelChangeSearch}
                                           placeholder="Search........"
                                           inputProps={{'aria-label': 'Search product here'}}/>
                                <IconButton type="button" aria-label="search">
                                    <SearchIcon/>
                                </IconButton>
                                {/*<Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>*/}

                            </Paper>
                        </Box>
                    </Col>
                </Row>
                {loading && <Row className="mt-3"> <SkeletonFileGeneral height={350} col={4} total={3}/> </Row>}
                {!loading && <> <Row className="mt-3">
                    {
                        data && Array.isArray(data) && data.length > 0 ? data.map((blog) => {
                            return (
                                <Col lg={4} md={6} className="g-3" style={cardStyles}>
                                    <BlogItemCard data={blog}/>
                                </Col>
                            )
                        }) : (<h6 className={'text-center'}>
                            There is no blog available currently Please come back later
                        </h6>)
                    }
                </Row>
                    <Row>
                        <Col>
                            <div className="text-center">
                                {isMoreData && (
                                    <Button disabled={btnLoading} type="submit" variant=""
                                            className="btn btn-primary3 my-3 "
                                            onClick={handelInfiniteScroll}>{btnLoading &&
                                    <CircularProgress size={16} color={"inherit"}/>}&nbsp; Load More</Button>
                                )}

                            </div>
                        </Col>
                    </Row>
                </>}
            </Container>
        </>
    );
};

export default BlogList;








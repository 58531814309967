import { React } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import banner from "../asset/banner.webp"
import imgOriginal from "../asset/original.webp"
import { cropText, formatMoney } from "../../hooks/useCustomHelpingMethod";
import { FaMoneyBill } from "react-icons/fa";
import { useAuth } from "../../hooks";
import { Badge } from "react-bootstrap";
import { styled, Tooltip, tooltipClasses, Zoom } from "@mui/material";

export default function SimilarProductShopCard(props) {
    const { authInfo } = useAuth();
    const navigate = useNavigate();

    const icon = props.part?.url_icon || props.part?.url_image || props.part?.url_image || props.image || banner;
    const title = props.part?.title || props.title || "";
    let priceUser = props.part?.amount_total || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            priceUser = props.part?.amount_total_dealer || 0;
        } else {
            priceUser = props.part?.amount_total_customer || 0;
        }
    }
    const moneySymbol = props.part?.amount_currency_symbol || "₹";
    const availability = props.part?.availability || "not-available";

    let discountPerUser = props.part?.rate_discount || 0;
    if (authInfo.profile) {
        if (authInfo.profile.user_type && authInfo.profile.user_type === 'dealer') {
            discountPerUser = props.part?.rate_discount_dealer || 0;
        } else {
            discountPerUser = props.part?.rate_discount_customer || 0;
        }
    }

    const style = { color: "#ee610f", fontSize: "1.2em" }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    return (
        <div onClick={() => navigate("/product/" + props.slug)} className={'text-decoration-none text-dark'}>
            <div className={"card w-100 card-custom card-link align-items-stretch"}>
                <div className="card-body px-2">
                    <div className='row'>
                        <div className=' col-xs-6 col-sm-4 col-md-12 col-lg-6 col-xxl-4 recently-viewed-img'>
                            <img className="img-fluid"
                                src={icon /*&& checkIfImageExists(icon)*/ ? icon : banner}
                                onError={(e) => e.target.src = banner}
                                alt={title} />
                            {props?.part?.setting?.is_original && props?.part?.setting?.is_original === 'yes' &&
                                <img src={imgOriginal} alt={title} className={'badge-top-left  original-img'} />}
                            {/*<Badge pill bg="success" className={'badge-top-left'}>Original</Badge>*/}
                            {availability && availability === "available" && discountPerUser && discountPerUser > 0 ?
                                <Badge pill bg="danger"
                                    className={'badge-top-right'}>-{discountPerUser}%</Badge> : ""}
                        </div>
                        <div className='col-xs-6 col-sm-8 col-md-12 col-lg-6 col-xxl-8'>
                            <div className='row'>
                                <LightTooltip title={title || ""} placement="top">
                                    <Link to={"/product/" + props.slug} className="text-decoration-none text-dark card-title shop-detail-title mb-2 fw-bold">
                                        <small> {cropText(title || "", 50)} </small>
                                    </Link>
                                </LightTooltip>
                            </div>
                            <div className='row'>
                                <p className="card-text servicesCard-sub-Heading fa fa-money-bill">
                                    <FaMoneyBill style={style} /> Price:
                                    <span className="shop-product-price float-end">
                                        {availability && availability !== "available" ?
                                            <Badge pill bg="danger">Out Of Stock</Badge> :
                                            (moneySymbol || "₹") + " " + (formatMoney(priceUser || 0))}
                                    </span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

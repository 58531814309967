import { React, useEffect, useState } from 'react'
import BookServiceAndJobSheet from './BookServiceAndJobSheet'
import BrandDisplay from './BrandDisplay'
import { Col, Row } from "react-bootstrap";
import { HomeTopSlider } from "./HomeTopSlider";
import FooterFeaturedBlock from "./FooterFeaturedBlock";
import ProductPartFeatured from "./ProductPartFeatured";
import { home } from "../../api/general";
import HomeBrandListSlider from "./HomeBrandListSlider";
import bannerFeatured from "../asset/front-web-banner-featured.webp";
import bannerSpecial from "../asset/front-web-banner-special.webp";
import bannerTrending from "../asset/front-web-banner-featured.webp";
import addImageFull from "../asset/Web Ad 3.webp"
import { HelmetCustomInclude } from "../HelmetCustomInclude";
import { showAdBanners } from "../../api/client";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import BannerForm from "./BannerForm";
import { ApiCall } from "../../api/HelpingMethod";
import MetaTagsUpdater from '../../Metautils/meta';

export default function HomePage() {
    const [pageTitle, setPageTitle] = useState("iService: Book Services at your Doorstep | Buy Spare Parts Online");
        const [metaKeywords, setMetaKeywords] = useState("About us, Service, Company, Parts, Repair, Original Parts, mobile repairing shop near me, Doorstep Services");
        const [metaDescription, setMetaDescription] = useState("We are the Best Mobile Phone Spare Parts Provider for all popular brands of mobile phones, Laptops, and tablets. 90% of orders are Deliver the same day.");
    const [metaImageUrl, setMetaImageUrl] = useState(null);

    const [dataHome, setDataHome] = useState({});
    const [loading, setLoading] = useState(true);
    const [bannerFeaturedProduct, setBannerFeaturedProduct] = useState(null);
    const [bannerSpecialProduct, setBannerSpecialProduct] = useState(null);
    const [bannerTrendingProduct, setBannerTrendingProduct] = useState(null);
    const [bannerProductType, setBannerProductType] = useState(null);

    const getApiCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_home_aside_featured_product"
        }).then((r) => {
            setBannerFeaturedProduct(r?.data);
        });
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_home_aside_special_product"
        }).then((r) => {
            setBannerSpecialProduct(r?.data);
        });
        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_home_aside_trending_product"
        }).then((r) => {
            setBannerTrendingProduct(r?.data);
        });

        await ApiCall('dealer/advertisement', {
            key_advertisement: "front_home_between_part_type_products_list_block"
        }).then((r) => {
            setBannerProductType(r?.data);
        });

    }

    const getDetails = async () => {
        setLoading(true)
        const { data } = await home();
        setDataHome(data?.data);
        if (data?.data) {
            if (data?.data?.title) setPageTitle(data?.data?.title);
            
            if (data?.data?.keywords) setMetaKeywords(data?.data?.keywords)
            if (data?.data?.description) setMetaDescription(data?.data?.description)
                
            if (data?.data?.image) setMetaImageUrl(data?.data?.image);
        }
        setLoading(false)
    };

    useEffect(() => {
        getDetails();
        getApiCall();
    }, []);

    let iPT = 0;

    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }
const metaData={
    title:pageTitle,
    keywords:metaKeywords,
    description:metaDescription,
    image:metaImageUrl,
    url: window.location.href 
}
    return (
        <>

            {/* <HelmetCustomInclude url={"/"} title={pageTitle} image={metaImageUrl} description={metaDescription} keywords={metaKeywords}/> */}
            <MetaTagsUpdater metaData={metaData} />
            <div className='bannerhome mt-sm-3 mt-0'>
                {loading && <Row className={'g-1'}>
                    <Col xl={8} xs={12} className={"px-md-3"}>
                        {loading && <SkeletonFileGeneral height={400} col={12} total={1} />}
                    </Col>
                    <Col xl={4} xs={12} className={' justify-content-center px-md-5 px-0'}>
                        {loading && <SkeletonFileGeneral height={400} col={12} total={1} />}
                    </Col>
                </Row>}
                {!loading && <Row className={'g-1'}>
                    <Col xl={8} xs={12} className={"px-md-3"}>
                        <HomeTopSlider data={dataHome?.offers || []} />
                    </Col>
                    <Col xl={4} xs={12} className={'{/*d-flex*/} justify-content-center px-md-5 px-0'}>
                        <BannerForm />
                    </Col>
                </Row>}
            </div>
            <BrandDisplay data={dataHome?.categories || []} />

            <BookServiceAndJobSheet />

            <Row className=' ms-0 me-0 p-0 gx-0'>
                {showAdBanners && bannerFeaturedProduct &&
                    <Col className='d-flex  align-items-center ms-0 mt-3' xs={12} lg={3} sm={4}>
                        {loading &&
                            <Col className='mt-4 mx-3'><SkeletonFileGeneral height={300} col={12} total={1} /> </Col>}
                        {!loading &&
                            <img onClick={() => redirectBanner(bannerFeaturedProduct?.url_redirect)}
                                src={bannerFeaturedProduct?.url_image || bannerFeatured}
                                className='home-slider-img img-fluid pointer'
                                alt={'no image'} />}
                    </Col>}
                <Col xs={12} lg={showAdBanners && bannerFeaturedProduct?.url_image ? 9 : 12}
                    sm={showAdBanners && bannerFeaturedProduct?.url_image ? 8 : 12}>
                    <ProductPartFeatured type={"ad"} title={"Featured Products"} data={dataHome?.part_features || []}
                        loading={loading}
                        setLoading={setLoading} />
                </Col>
            </Row>
            <Row className='ms-0 me-0 p-0 gx-0'>
                {(showAdBanners && bannerSpecialProduct?.url_image) &&
                    <Col className='d-flex align-items-center ms-0 mt-3' xs={12} lg={3} sm={4}>
                        {loading &&
                            <Col className='mt-4 mx-3'><SkeletonFileGeneral height={300} col={12} total={1} /> </Col>}
                        {!loading &&
                            <img src={bannerSpecialProduct?.url_image || bannerSpecial}
                                onClick={() => redirectBanner(bannerSpecialProduct?.url_redirect)}
                                className='home-slider-img img-fluid pointer'
                                alt={'no image'} />}
                    </Col>}
                <Col xs={12} lg={showAdBanners && bannerSpecialProduct?.url_image ? 9 : 12}
                    sm={showAdBanners && bannerSpecialProduct?.url_image ? 8 : 12}>
                    <ProductPartFeatured type={"ad"} title={"Special Products"} data={dataHome?.part_specials || []}
                        loading={loading}
                        setLoading={setLoading} />
                </Col>
            </Row>
            <Row className='ms-0 me-0 p-0 gx-0'>
                {(showAdBanners && bannerTrendingProduct?.url_image) &&
                    <Col className='d-flex align-items-center ms-0 mt-3' xs={12} lg={3} sm={4}>
                        {loading &&
                            <Col className='mt-4 mx-3'><SkeletonFileGeneral height={300} col={12} total={1} /> </Col>}
                        {!loading &&
                            <img src={bannerTrendingProduct?.url_image || bannerTrending}
                                onClick={() => redirectBanner(bannerTrendingProduct?.url_redirect)}
                                className='home-slider-img img-fluid pointer'
                                alt={'no image'} />}
                    </Col>}
                <Col xs={12} lg={showAdBanners && bannerTrendingProduct?.url_image ? 9 : 12}
                    sm={showAdBanners && bannerTrendingProduct?.url_image ? 8 : 12}>
                    <ProductPartFeatured type={"ad"} title={"Trending Products"} data={dataHome?.part_trendings || []}
                        loading={loading}
                        setLoading={setLoading} />
                </Col>
            </Row>


            {dataHome?.product_types && Array.isArray(dataHome?.product_types) && dataHome?.product_types.length > 0 && (
                <>
                    {dataHome?.product_types.map((productType, index) => {
                        if (productType?.parts && productType?.parts?.length > 0) {
                            iPT++;
                            return (
                                <>

                                    <ProductPartFeatured
                                        index={index}
                                        key={"product-type-" + (productType?.slug || productType?.id || index)}
                                        title={(productType?.title || "") + " "}
                                        data={productType?.parts || []}
                                        loading={loading}
                                        setLoading={setLoading} />

                                    {iPT !== (dataHome?.product_types.length - 1) && bannerProductType && showAdBanners &&
                                        <Row key={index} className='ms-sm-4 me-sm-4 ms-0 me-0  ps-sm-2 pe-sm-2 ps-0 pe-0'>
                                            <Col className='' lg={12}>
                                                <img src={bannerProductType?.url_image || addImageFull}
                                                    onClick={() => redirectBanner(bannerProductType?.url_redirect)}
                                                    className='w-100 banner-image pointer'
                                                    alt={'no advertisement'} />
                                            </Col></Row>}

                                </>
                            );
                        } else {
                            return <></>
                        }

                    })}
                </>
            )}


            <HomeBrandListSlider brands={dataHome?.brands || []} />
            {/*<HowItWorks/>*/}
            {/*<ClientSay/>*/}
            {/*<WhyChooseUs/>*/}
            {/*<Crouser/>*/}
            <FooterFeaturedBlock colXS={1} colSM={2} colMd={3} colLg={4} colXL={5} />
        </>
    )
}

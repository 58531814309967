import React, {useEffect, useState} from "react";
import {get_general_page_content} from "../../api/general";
import {useLocation, useSearchParams} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import {Helmet} from "react-helmet";
import {cropText, getFullPageTitle} from "../../hooks/useCustomHelpingMethod";
import Row from "react-bootstrap/Row";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import {useParams} from "react-router";
import {appFooterLogo} from "../../api/constant";
// import {HelmetCustomInclude} from "../HelmetCustomInclude";
import MetaTagsUpdater from "../../Metautils/meta";


export default function GeneralPage() {
    const [pageTitle, setPageTitle] = useState("General Page");

    const {slugPage} = useParams();
    const [isLoading, setIsLoading] = useState(true)


    var [pageData, setPageData] = useState({})
    var getAboutData = async (slugPage) => {
        setIsLoading(true);
        const {data} = await get_general_page_content({slug_page: slugPage})
        setPageData(data)
        setIsLoading(false);

        if (data?.data) {
            if (data?.data?.title) setPageTitle(getFullPageTitle(data?.data?.title || ""));
            // if (data?.data?.page_keywords) setMetaKeywords(data?.data?.page_keywords);
            // if (data?.data?.page_description) setMetaDescription(cropText(data?.data?.page_description, 200));
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // setSlug();
        getAboutData(slugPage).then(r => r);
    }, [slugPage]);
const metaData={
    url:window.location.href,
    title:pageTitle,
 
}
    return (
        <div>
            {/* <HelmetCustomInclude url={slugPage === 'about' ? "about" : "page/" + (slugPage || "")}
                                 title={"General Pages"} image={null}/> */}
                                 <MetaTagsUpdater metaData={metaData}/>

            <Backdrop sx={{color: 'var(--primary)', zIndex: (theme) => theme.zIndex.drawer + 1}} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className="ourservices">
                <h3 className=" mx-5 servicesHeading">{pageData?.title || "About iService"}</h3>
                <p className="mx-5 subHeading"></p>
            </div>
            <div className="bannerAbout my-2">
                <Row>
                    {isLoading && <SkeletonFileGeneral total={1} col={4} colLg={4} height={30}/>}
                    {isLoading && <SkeletonFileGeneral total={1} col={12} colLg={12} height={300}/>}
                </Row>
                {!isLoading && <>
                    <div className="banner-in-about">
                        <div>
                            <div
                                dangerouslySetInnerHTML={{__html: (pageData?.description || "<p>Content will be updated soon</p>")}}/>
                        </div>
                    </div>
                </>}

            </div>
        </div>
    );
}

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { updateMetaTags } from './metautils'; // Import the utility function

const MetaTagsUpdater = ({ metaData }) => {
  const location = useLocation(); // Get the current route location

  useEffect(() => {
    if (metaData) {
      // Call the utility function to update meta tags
      updateMetaTags(metaData);
    }
  }, [location.pathname, metaData]); // Update on route change or metaData change

  return null;
};

export default MetaTagsUpdater;

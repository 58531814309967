import React, {useEffect, useState} from "react";
import {
    category_list,
    model,
    part_list,
    product_listing_by_id,
    product_model,
} from "../../api/general";
import MoreButton from "./MoreButton";
import {useNavigate} from "react-router-dom";
import DialogTempNotAvailable from "../DialogTempNotAvailable";
import {useAuth, useNotification} from "../../hooks";
import {book_service} from "../../api/booking";
import {Button, CircularProgress} from "@mui/material";
import Select from 'react-select';
export default function TabsForm1() {
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [brand_id, setbrand_id] = useState();
    const [model_slug, setModel_slug] = useState();
    const [brand_slug, setBrand_slug] = useState();
    const [category_slug, setCategory_slug] = useState();
    const [modalId, setModalId] = useState();
    const {setShowLoginModel} = useNotification();
    const {authInfo} = useAuth();
    const {setOpenBackDrop} = useNotification();
    const [loading, setLoading] = useState(false)


    const navigate = useNavigate();
    const getCategories = async () => {
        setOpenBackDrop(true);
        const {data} = await category_list({is_service: "yes"});
        setCategories(data.data);
        setOpenBackDrop(false);
    };

    useEffect(() => {
        getCategories();
        setBrands([]);
        setModels([]);
    }, []);

    const getBrands = async () => {
        setOpenBackDrop(true);
        if (categoryId) {
            const {data} = await model({category_id: categoryId});
            setBrands(data?.data || []);
        }
        setOpenBackDrop(false);

    };
    useEffect(() => {
        getBrands();
    }, [categoryId]);

    const [notAvOpen, setNotAvOpen] = useState(false);
    const onClick = async (e) => {
        e.preventDefault();
        setLoading(true);

        let url = "/book-repair?";


        if (modalId) {
            const inputData = {
                brand_id: brand_id,
                category_id: categoryId,
                model_id: modalId,
                id: authInfo.profile.id,
                user_type: authInfo.profile.user_type,
                // inputData.token_order = token;
                step: "device-info"
            };

            const {data, error} = await book_service(inputData);

            // console.log("response--", data)
            if (data.error === false) {

                navigate("/book-repair?token=" + data?.data?.token + "&" + "step=" + data?.data.step + "&" + "step-count=" + 1)
                setLoading(false);
            }
            setLoading(false);
        } else {
            if (category_slug) url += "&category-slug=" + category_slug;
            if (brand_slug) url += "&brand-slug=" + brand_slug;
            if (model_slug) url += "&model-slug=" + model_slug;
            navigate(url);
        }


    };
    // const handleChangeCategory = (e) => {
    //     setCategoryId(e.target.value);
    //     setCategory_slug(e.target.selectedOptions[0].getAttribute('slug'))
    // };

    const handleChangeCategory = (selectedOption) => {
        console.log("Selected Category:", selectedOption); 
        setCategoryId(selectedOption.value);
        setCategory_slug(selectedOption.slug);
        console.log(categoryId)
        setbrand_id(null); // Reset brand
        setModels([]); // Reset models
        setModalId(null); // Reset model
    };
    const handleChangeBrand = async (selectedOption) => {
        setOpenBackDrop(true);
    
        // Get the slug and id from the selected option
        setBrand_slug(selectedOption.slug);
        setbrand_id(selectedOption.value);
    
        // Fetch models based on the selected brand
        const { data } = await product_model({ brand_id: selectedOption.value });
      
        // Set the models data
        setModels(data?.data || []);
     
        setOpenBackDrop(false);
    };
    const handleChangeModel = (selectedOption) => {
        setModel_slug(selectedOption.slug);
        setModalId(selectedOption.value);
        // setModel_slug(event.target.selectedOptions[0].getAttribute('slug'));
        // setModalId(event.target.value)
    };

    return (
        <div>
            <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen}/>
            <form className="">
                {/* <select onChange={handleChangeCategory} className="form-select my-5" aria-label="Select Category">
                    <option className="tabs-dropdown-placeholder">
                        Select Category
                    </option>
                    {categories?.map((element) => {
                        return <option key={element?.token} value={element.id}
                                       slug={element.slug}>{element.title}</option>;
                    })}
                </select> */}
<div className="mt-5 important">
                  <Select
    //   value={categories.find(option => option.id === categoryId) || null} // Use categoryId to find the selected option
   // Set selected value based on categoryId
        value={categoryId?.category_id}
        onChange={handleChangeCategory} // Update categoryId when a selection is made
        options={categories.map((element) => ({
          value: element.id,
          label: element.title,
          slug: element.slug,
        }))} // Map categories to react-select options
        placeholder="Select Category"
        isSearchable
        isClearable
      />
</div>
                {/* <select onChange={handleChangeBrand} className="form-select my-5" aria-label="Select Brand">
                    <option>Select Brand</option>
                    {brands === null || !brands?.length ? (
                        <></>
                        // <option value="1">Please Select Category</option>
                    ) : (
                        brands?.map((element) => {
                            return <option key={element?.token} value={element.id}
                                           slug={element.slug}>{element.title}</option>;
                        })
                    )}
                </select> */}
               <div className="mt-5 important">
               <Select
               value={brand_id?.brand_id} // Set selected value based on categoryId
  
//   value={brand_id.brand_id} // Set selected brand
  onChange={handleChangeBrand} // Handle brand change
  options={brands.map((element) => ({
    value: element.id,
    label: element.title,
    slug: element.slug
  }))} // Map brands to react-select options
  placeholder="Select Brand"
  isSearchable
  isClearable

/>
</div>
                {/* <select onChange={handleChangeModel} className="form-select my-4" aria-label="Select Model">
                    <option>Select Model</option>
                    {models === null || !models?.length ? (
                        <option value="1">Please Select Model</option>
                    ) : (
                        models?.map((element) => {
                            return <option key={element?.token} value={element.id}
                                           slug={element.slug}>{element.title}</option>;
                        })
                    )}
                </select> */}
                <div className="mt-5 important">

{/* <Select
value={categories.find(option => option.id === modalId?.model_id) || null} // Set selected value based on categoryId
  
//   value={modalId.model_id} // Set the selected model
  onChange={handleChangeModel} // Handle model change
  options={models.map((element) => ({
    value: element.id,
    label: element.title,
    slug: element.slug
  }))} // Map models to react-select options
  placeholder="Search and select Model"
  isSearchable
  isClearable
  isDisabled={!brand_id} // Disable until a brand is selected
/> */}
<Select
    value={modalId?.model_id}  // Set the selected value based on modelId
    onChange={handleChangeModel}  // Handle model change
    options={models.map((element) => ({
        value: element.id,
        label: element.title,
        slug: element.slug
    }))}  // Map models to react-select options
    placeholder="Select Model"
    isSearchable
    isClearable
    // Disable until a brand is selected
/>

</div>
<div className="mt-4">
                <div className="d-flex justify-content-center align-item-center">
                    {
                        authInfo.profile.id ?
                            <button
                                disabled={loading} type="button"
                                className={"sign-up-button float-end " + (loading && 'disabled-button')}
                                onClick={onClick}>
                                {loading &&
                                    <CircularProgress size={16} color={"inherit"}/>} &nbsp; Book Repair
                            </button> :
                            <button className={'sign-up-button'} onClick={() => setShowLoginModel(true)} type="button">
                                Book Repair</button>
                    }

                </div>
                </div>
            </form>
        </div>
       
    );
}
